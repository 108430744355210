<template>
  <div>
    <el-drawer class="taskDetail-drawer" title="工单详情" :visible.sync="drawer" :append-to-body="true" direction="rtl" :before-close="() => handleClose(true)">
      <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="drawer-container">
        <div v-if="detailInfo" class="drawer-left">
          <div style="display: flex;justify-content: space-between">
            <div>
              <div class="detail-title">
                <div class="tit-info">
                    <div class="orderName">{{ detailInfo.taskName || "--" }}</div>
                    <div v-if="detailInfo.status == '1'" class="common-tableStatus-green">进行中</div>
                    <div v-if="detailInfo.status == '2'" class="common-tableStatus-blue">已完成</div>
                    <div v-if="detailInfo.status == '3'" class="common-tableStatus-grey">终止</div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div v-if="detailInfo.status == 1" style="color: #52C419;margin-left: 16px">处理中</div>
                    <div v-else-if="detailInfo.status == 2" style="color: #1890ff;margin-left: 16px">已通过</div>
                    <div v-else-if="detailInfo.status == 3 && detailInfo.createId != userId" style="color: #FF4D4F;margin-left: 16px">已拒绝</div>
                    <div v-else-if="detailInfo.status == 3 && detailInfo.createId == userId" style="color: #aaa;margin-left: 16px">已撤销</div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div style="margin-left: 16px" v-if="detailInfo.priority == '4'" class="common-tableStatus-red">紧急</div>
                    <div style="margin-left: 16px" v-if="detailInfo.priority == '3'" class="common-tableStatus-orange">高</div>
                    <div style="margin-left: 16px" v-if="detailInfo.priority == '2'" class="common-tableStatus-blue">中</div>
                    <div style="margin-left: 16px" v-if="detailInfo.priority == '1'" class="common-tableStatus-grey">低</div>
                </div>
              </div>
              <div class="detail-content">
                  <div class="detail-item">类型名称：<span>{{detailInfo.workTypeName || '--'}}</span></div>
                  <div class="detail-item ">逾期时长：<span>{{detailInfo.overdueDuration || '--'}}</span></div>
                  <div class="detail-item">剩余处理时间：<span>{{detailInfo.surplusDuration || '--'}}</span></div>
                  <div class="detail-item">完成工单耗时：<span>{{detailInfo.completeDuration || '--'}}</span></div>
                  <!-- <div class="detail-item">负责人：<span>{{detailInfo.chargeName || '--'}}</span></div> -->
                  <div class="detail-item" style="width: 49%">计划开始时间：<span>{{detailInfo.planStartTime ? DateTimeTransform(detailInfo.planStartTime) : '--'}}</span></div>
                  <div class="detail-item" style="width: 49%">计划完成时间：<span>{{detailInfo.planEndTime ? DateTimeTransform(detailInfo.planEndTime) : '--'}}</span></div>
                  <div class="detail-remark"><span>备注：</span> <span>{{detailInfo.remark || '--'}}</span></div>
              </div>
            </div>
            <div>
              <div v-if="detailInfo.status == '1' && detailInfo.createId == userId" class="handle-btn" @click="() => { $router.push(`/work-order/approve/launch-approve?id=${detailInfo.id}`) }">编辑工单</div>
              <div v-if="detailInfo.status == '1' && detailInfo.createId == userId" class="grey-btn" @click="() => { closeVisible = true; operateType = 2 }">撤销工单</div>
            </div>
          </div>
          <div class="upload-container">
            <div v-if="fileList && fileList.length" class="fileList-container">
              <div v-for="(item, index) in fileList" :key="index" class="fileItem">
                <div class="fileItem-name">
                  <common-picture :fileUrl="item.url" :fileType="item.type" :fileName="item.name"></common-picture>
                </div>
                <div class="fileItem-btn-Container">
                  <div class="fileItemBtn" @click="() => {download(item.url)}">下载</div>
                </div>
              </div>
            </div>
            <div v-if="!fileList || !fileList.length" class="fileList-container">
              <div class="fileItem">
                暂无附件
              </div>
            </div>
          </div>
          <div v-if="detailInfo.changeMap">
            <div class="detailTitle">关联信息</div>
            <div class="aboutInfo">
              <div>订单编号：<span>{{detailInfo.changeMap['订单编号'] || '--'}}</span></div>
              <div>订单应收金额：<span>{{detailInfo.changeMap['订单应收金额'] || '--'}}</span></div>
              <div>改价类型：<span>{{detailInfo.changeMap['改价类型'] || '--'}}</span></div>
              <div>店铺：<span>{{detailInfo.changeMap['店铺'] || '--'}}</span></div>
              <div>时间段：<span>{{detailInfo.changeMap['时间段'] || '--'}}</span></div>
              <div>子账号数量：<span>{{detailInfo.changeMap['子账号数量'] || '--'}}</span></div>
              <div>调价金额：<span>{{detailInfo.changeMap['调价金额'] || '--'}}</span></div>
              <div>改价结果：<span>{{detailInfo.changeMap['改价结果'] || '--'}}</span></div>
              <div style="width: 90%">改价说明：<span>{{detailInfo.changeMap['改价说明'] || '--'}}</span></div>
            </div>
          </div>
          <div class="person-container">
            <div>审批人： <span class="person-item">{{detailInfo.dealName || '--'}}</span> </div>
            <div>抄送人： <span class="person-item">{{detailInfo.sendName || '--'}}</span> </div>
          </div>
          <el-tabs class="leftTabs" v-model="activeNameLeft">
            <el-tab-pane label="工单流程" name="1">
              <approve-process :activeIndex='activeIndex' :currentIndex="currentIndex"  @closeVisibleShow="closeVisibleShow" @editTabs="editTabs" @getTableParams="getTableParams" v-if="detailInfo" :detailInfo="detailInfo"></approve-process>
              <br/> <br/>
            </el-tab-pane>
            <el-tab-pane label="基本信息" name="2">
                <div class="detail-content">
                    <div class="DisableEdit">创建人： <span class="common-clr-0a6"> {{(detailInfo.createName) || "--"}}</span></div>
                    <div class="DisableEdit">创建时间： <span class="common-clr-0a6"> {{ detailInfo.createTime ? DateTimeTransform(detailInfo.createTime) : "--"}}</span></div>
                    <div class="DisableEdit">创建人所属公司： <span class="common-clr-0a6"> {{(detailInfo.companyName) || "--"}}</span></div>
                    <div class="DisableEdit">创建人所属部门： <span class="common-clr-0a6"> {{(detailInfo.departmentName) || "--"}}</span></div>
                    <div class="DisableEdit">最后修改人： <span class="common-clr-0a6"> {{(detailInfo.updateName) || "--"}}</span></div>
                    <div class="DisableEdit">最后修改时间： <span class="common-clr-0a6"> {{detailInfo.updateTime ? DateTimeTransform(detailInfo.updateTime) : "--"}}</span></div>
                    <div class="DisableEdit">关闭时间： <span class="common-clr-0a6"> {{detailInfo.terminationTime ? DateTimeTransform(detailInfo.terminationTime) : "--"}}</span></div>
                    <div class="DisableEdit">完成时间： <span class="common-clr-0a6"> {{detailInfo.finishTime ? DateTimeTransform(detailInfo.finishTime) : "--"}}</span></div>
                    <div class="DisableEdit">最后处理时间： <span class="common-clr-0a6"> {{(detailInfo.dealTime) || "--"}}</span></div>
                    <br/><br/>
                </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="富文本" name="3">
                <div id="preview" v-on:paste="handlePaste">
                    <span>将图片按Ctrl+V 粘贴至此处</span>
                </div>
            </el-tab-pane> -->
          </el-tabs>
        </div>
        <div class="drawer-right">
          <el-tabs style="height: 100%;display: flex;flex-direction: column;" v-model="activeName">
            <el-tab-pane label="工单动态" name="1">
                <div class="common-multiple-container">
                    <div class="label"><span style="width: 100%">通知人</span></div>
                    <div class="content">
                        <div v-for="(item, index) in noticeList" :key="index" class="multiple-item">{{item.name}}</div>
                    </div>
                    <div class="multiple-btn" @click="() => { personShow() }">选择人员</div>
                </div>
              <div>
                <el-input :autosize="{ minRows: 6, maxRows: 6 }" type="textarea" v-model="recordContent" placeholder="请输入反馈内容"></el-input>
                <div class="appeal-commit">
                  <el-button @click="() => {addWorkLog()}" class="common-screen-btn" type="primary" >发布反馈</el-button>
                </div>
                <br/>
              </div>
              <work-log-bg v-if="followRecordList" :followRecordList="followRecordList"></work-log-bg>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
    <el-dialog v-dialogDrag title="选择人员" :append-to-body="true" :visible.sync="personVisible" width="630px" :before-close="() => { personVisible = false }" :close-on-click-modal="false">
        <div class="common-chooseItem-container">
            <div v-for="(item, index) in checkedList" :key="index" class="common-choose-item">
                <span>{{item.name}}</span><i @click="() => { deletePerson(item) }" class="el-icon-circle-close"></i>
            </div>
        </div>
        <br/><br/>
        <div class="common-person-container">
            <div class="person-title">
                <div class="left-text">组织成员</div>
                <div class="right-seach">
                    <el-input class="seach-input" placeholder="请输入内容" prefix-icon="el-icon-search" @change="(val) => seachFun(val)" v-model="seachInfo"></el-input>
                </div>
            </div>
            <div class="person-content">
                <div class="content-left">
                    <el-tree :data="departData" :props="defaultProps" @node-click="(val) => { departTreeFun(val) }"></el-tree>
                </div>
                <div class="content-right">
                    <div v-for="( item, index ) in personData" :key="index">
                        <el-checkbox v-model="item.checked" @change="(val) => { changePerson(val, item) }">{{item.nickName}}</el-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button class="common-screen-btn" @click="() => { personVisible = false }">取 消</el-button>
            <el-button class="common-screen-btn" type="primary" @click="() => { noticeList =  [...checkedList]; personVisible = false }">确 定</el-button>
        </span>
    </el-dialog>
      <el-dialog v-loading="closeLoading" element-loading-text="拼命加载中..." v-dialogDrag title="关闭工单" :append-to-body="true" width="530px" :visible.sync="closeVisible" :close-on-click-modal="false">
        <el-form :model="closeForm" label-width="80px" class="demo-ruleForm">
          <el-form-item label="说明：">
            <el-input class="common-form-input_100" type="textarea" maxlength="500" show-word-limit :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入内容" v-model="closeForm.terminationRemark"></el-input>
          </el-form-item>
          <el-form-item>
            <div>
                <div class="common-uploadBnList-container">
                    <div class="fileBtn-container">
                        <div class="fileBtn"> 上传附件 <input class="file-input" type="file" @input="(e) => {uploadFile(e)}"></div>
                        上传文件及图片，大小不超过50MB
                    </div>
                    <!-- <div class="fileList-container">
                        <div v-for="(item, index) in closeFileList" :key="index" class="fileList-item">
                            <div class="item-name">
                                <common-picture :fileUrl="item.filedUrl" :fileType="item.filedType" :fileName="item.filedName"></common-picture>
                            </div>
                            <div @click="() => {deleteFlie(index)}" class="deleteBtn">删除</div>
                        </div>
                    </div> -->
                </div>
                <div class="common-uploadBnList-container">
                    <div class="fileList-container">
                        <div v-for="(item, index) in closeFileList" :key="index" class="fileList-item">
                            <div class="item-name">
                                <common-picture :fileUrl="item.filedUrl" :fileType="item.filedType" :fileName="item.filedName"></common-picture>
                            </div>
                            <div @click="() => {deleteFlie(index)}" class="deleteBtn">删除</div>
                        </div>
                    </div>
                </div>
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button class="common-screen-btn" @click="closeVisible = false">取 消</el-button>
            <el-button class="common-screen-btn" type="primary" @click="() => { closeTask(operateType) }">确 定</el-button>
            <!-- <el-button class="common-screen-btn" type="primary" @click="() => { closeTask(operateType) }">确 定</el-button> -->
        </span>
      </el-dialog>
  </div>
</template>
<script>
import { Config, tableColumn, DateTransform, DateTimeTransform, arrGroup } from "../../../utils/index.js"
import CommonPicture from "../../../components/common/commonPicture.vue"
import { taskDetail, addWorkLog, closeTask } from "../../../service/workOrder.js"
import { commonDepartAll, personList, workLog } from "../../../service/common.js"
import { upload } from "../../../service/upload.js";
import WorkLogBg from "./workLogBg.vue"
import ApproveProcess from "./approveProcess.vue"
export default {
  props: ["drawer", "handleClose", "companyId"],
  components: { CommonPicture, WorkLogBg, ApproveProcess },
  data() {
    return {
        // content: '',
      operateType: 2,
      DateTransform,
      DateTimeTransform,
      loading: false,
      personVisible: false,
      closeLoading: false,
      closeVisible: false,
      closeFileList: [],
      closeForm: {
        terminationRemark: ''
      },
      userId: this.$sto.get(Config.constants.userInfo).user_id,
      tableColumn,
      Config,
      fileList:[],
      activeName: "1", // tabs
      activeNameLeft: "1", // 左侧tabs
      recordContent: '', // 工作小计
      detailInfo: '', // 详情
      tableRow: {},
      departData: [], // 部门列表
      noticeList: [], // 通知人
      checkedList: [], // 选中的通知人
      personData: [], // 人员
      seachInfo: '', // 搜索人员
      defaultProps: {
            children: 'child',
            label: 'name'
        },
      followRecordList: [], // 工作日志
      drawerSettlement: false, // 发起结算弹窗
      voidLoading: false,
      voidVisible: false,
      voidSettleForm: {
        cancellationState: '',
        id: ''
      },
      voidSettleRules: { // 作废-校验规则
        cancellationState: [{ required: true, message: "请填写作废原因", trigger: "change" }]
      },
      activeIndex: 0,
      currentIndex: 0,
    };
  },
  methods: {
    seachFun (val) { // 搜索人员
      let params = {
        companyId: this.companyId,
        name: val,
        pageNum: 1,
        pageSize: 999999
      }
      this.personList(params)
    },
    closeVisibleShow() {
      this.operateType = 1
      this.closeVisible = true
    },
    async deleteFlie (i) {  //  删除文件
      let closeFileList = [...this.closeFileList]
      closeFileList = closeFileList.filter((item, index)=>{
        return i != index
      })
      this.closeFileList = closeFileList
    },
    async uploadFile (e) { // 上传文件
      let closeFileList = [...this.closeFileList]
      let file = e.target.files[0];
      let fileName = file.name;
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        e.target.value = "";
        return this.$message.error('文件大小不能超过50M')
      }
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
      }
      this.loading = true
      let { data, code } = await upload({ file: file });
      this.loading = false
      if ( code == 200 ) {
        this.$message.success("上传成功")
        closeFileList.push({ filedName: fileName, filedUrl: data.filename, filedId: data.id, filedType: Config.fileType.imgType.includes(format) ? 'imgText' : 'file'})
        this.closeFileList = closeFileList
      }
      e.target.value = ''
    },
    async closeTask(operateType) { // 关闭工单
      let closeForm = {...this.closeForm}
      let closeFileList = [...this.closeFileList]
      if (closeFileList && closeFileList.length) {
          let terminationRemarkFileName = []
          let terminationRemarkFileUrl = []
          closeFileList.map((item) => {
              terminationRemarkFileName.push(item.filedName)
              terminationRemarkFileUrl.push(item.filedUrl)
          })
          closeForm.terminationRemarkFileName = terminationRemarkFileName.join(',')
          closeForm.terminationRemarkFileUrl = terminationRemarkFileUrl.join(',')
      }
      closeForm.taskId = this.detailInfo.id
      closeForm.operateType = operateType
      let { code } = await closeTask(closeForm)
      if (code == 200) {
        this.taskDetail(this.detailInfo.id)
        this.workLog(this.detailInfo.id)
        this.closeVisible = false
        this.$message.success("关闭工单成功")
      }
    },
    deletePerson (row) { // 删除选中人员
        let checkedList = [...this.checkedList]
        let personData = [...this.personData]
        checkedList = checkedList.filter((item) => {
            return row.id != item.id
        })
        personData.map((item) => {
            if (row.id == item.id) {
                item.checked = false
            }
        })
        this.checkedList = checkedList
        this.personData = personData
    },
    changePerson(val, row) { // 选人员变动
        let checkedList = [...this.checkedList]
        if (val) {
            checkedList.push({id: row.id, name: row.nickName})
        } else {
            checkedList = checkedList.filter((item) => {
                return row.id != item.id
            })
        }
        this.checkedList = checkedList
    },
    departTreeFun (val) { //部门选择
        let detailInfo = {...this.detailInfo}
        let params = {
            companyId: detailInfo.companyId,
            userDepartId: val.id,
            pageNum: 1,
            pageSize: 999999
        }
        this.personList(params)
    },
    async personList(params) { // 获取人员
        let { data } = await personList(params)
        let checkedList = [...this.checkedList]
        if (data.records && data.records.length) {
            data.records.map((item) => {
                item.checked = false
                checkedList.map((itemTwo) => {
                    if (item.id == itemTwo.id) {
                        item.checked = true
                    }
                })
            })
        }
        this.personData = data.records || []
    },
    async commonDepartAll(companyId) { //获取部门
        let { data } = await commonDepartAll({}, companyId)
        this.departData = data || []
    },
    personShow() { // 选择人员
        this.personData = []
        this.checkedList = this.noticeList
        this.personVisible = true
    },
    download(url) { // 下载
      window.location.href = url
    },
    async addWorkLog() { // 工作记录发布按钮
      let params = {
        taskId: this.detailInfo.id,
        feedBack: this.recordContent,
      };
      let notice = []
      let noticeList = [...this.noticeList]
      if (noticeList && noticeList.length) {
        noticeList.map((item) => {
          notice.push(item.id)
        })
        params.sendIds = notice.join(',')
      } else { return this.$message.error("请选择通知人") }
      this.loading = true
      let { code } = await addWorkLog(params)
      this.loading = false
      if (code == 200) {
        this.taskDetail(this.detailInfo.id)
        this.workLog(this.detailInfo.id)
        this.recordContent = "";
        this.noticeList = []
        this.$message.success("添加成功");
      }
    },
    getTableParams (row) { // 获取列表的值
      this.taskDetail(row.id)
      this.workLog(row.id)
      this.recordContent = "";
      this.noticeList = []
    },
    editTabs(index) {
      this.activeIndex = index
    },
    async taskDetail (id) {
      this.loading = true
      let { data } = await taskDetail({taskId: id})
      this.commonDepartAll(data.companyId)
      this.loading = false
      if (data.remarkFileName && data.remarkFileName.length) {
        let fileList = []
        let remarkFileName = data.remarkFileName.split(',')
        let remarkFileUrl = data.remarkFileUrl.split(',')
        remarkFileName.map((item, index) => {
          fileList.push({url: remarkFileUrl[index], name: item, type: Config.fileType.imgType.includes(item.split('.')[item.split('.').length - 1]) ? 'imgText' : 'file'})
        })
        this.fileList = fileList
      } else {
        this.fileList = []
      }
      if (data.flowList) {
        data.flowList.map((item, index) => {
          if (item.finishFileName && item.finishFileUrl) {
            let finishFileList = []
            let finishFileName = item.finishFileName.split(',')
            let finishFileUrl = item.finishFileUrl.split(',')
            finishFileName.map((itemTwo, indexTwo) => {
              finishFileList.push({url: finishFileUrl[indexTwo], name: itemTwo, type: Config.fileType.imgType.includes(itemTwo.split('.')[itemTwo.split('.').length - 1]) ? 'imgText' : 'file'})
            })
            item.finishFileList = finishFileList
          }
          if (item.currentFlag == 1) {
              this.activeIndex = index
              this.currentIndex = index
          }
          if (item.fileName && item.fileUrl) {
            let itemFileList = []
            let fileName = item.fileName.split(',')
            let fileUrl = item.fileUrl.split(',')
            fileName.map((itemTwo, indexTwo) => {
              itemFileList.push({url: fileUrl[indexTwo], name: itemTwo, type: Config.fileType.imgType.includes(itemTwo.split('.')[itemTwo.split('.').length - 1]) ? 'imgText' : 'file'})
            })
            item.fileList = itemFileList
          }
        })
      }
      // if (data.relationParam) {
      //   data.relationParam = JSON.parse(data.relationParam)
      // }
      this.detailInfo = data
    },
    async workLog(id) { // 任务工单-工作日志
      this.followRecordList = ''
      let resData = (await workLog({id: id, type: 9})).data
      if (resData && resData.length) {
        resData.map((item) => {
          item.create_time = item.time
          let dateTime = item.time.split(' ')
          item.time = dateTime[1]
          if (item.value) {
            item.value = JSON.parse(item.value)
            if (item.type == 'dealWorkOrderTask') {
             if (item.value.finishFileName && item.value.finishFileUrl) {
                let itemFileList = []
                let finishFileName = item.value.finishFileName.split(',')
                let finishFileUrl = item.value.finishFileUrl.split(',')
                finishFileName.map((itemTwo, indexTwo) => {
                  itemFileList.push({url: finishFileUrl[indexTwo], name: itemTwo, type: Config.fileType.imgType.includes(itemTwo.split('.')[itemTwo.split('.').length - 1]) ? 'imgText' : 'file'})
                })
                item.value.fileList = itemFileList
              }
            }
            if (item.type == 'updateWorkOrderTask') {
              if (item.value.old.remarkFileName && item.value.old.remarkFileUrl) {
                let fileLfist = []
                let remarkFileName = item.value.old.remarkFileName.split(',')
                let remarkFileUrl = item.value.old.remarkFileUrl.split(',')
                remarkFileName.map((itemTwo, indexTwo) => {
                  fileLfist.push({url: remarkFileUrl[indexTwo], name: itemTwo, type: Config.fileType.imgType.includes(itemTwo.split('.')[itemTwo.split('.').length - 1]) ? 'imgText' : 'file'})
                })
                item.value.old.fileList = fileLfist
                item.value.old.flowList.map((itemTwo) => {
                  if (itemTwo.fileName && itemTwo.fileUrl) {
                    let itemFileList = []
                    let fileName = itemTwo.fileName.split(',')
                    let fileUrl = itemTwo.fileUrl.split(',')
                    fileName.map((itemThree, indexThree) => {
                      itemFileList.push({url: fileUrl[indexThree], name: itemThree, type: Config.fileType.imgType.includes(itemThree.split('.')[itemThree.split('.').length - 1]) ? 'imgText' : 'file'})
                    })
                    item.value.fileList = itemFileList
                  }
                })
              }
              if (item.value.new.remarkFileName && item.value.new.remarkFileUrl) {
                let fileLfist = []
                let remarkFileName = item.value.new.remarkFileName.split(',')
                let remarkFileUrl = item.value.new.remarkFileUrl.split(',')
                remarkFileName.map((itemTwo, indexTwo) => {
                  fileLfist.push({url: remarkFileUrl[indexTwo], name: itemTwo, type: Config.fileType.imgType.includes(itemTwo.split('.')[itemTwo.split('.').length - 1]) ? 'imgText' : 'file'})
                })
                item.value.new.fileList = fileLfist
                item.value.new.flowList.map((itemTwo) => {
                  if (itemTwo.fileName && itemTwo.fileUrl) {
                    let itemFileList = []
                    let fileName = itemTwo.fileName.split(',')
                    let fileUrl = itemTwo.fileUrl.split(',')
                    fileName.map((itemThree, indexThree) => {
                      itemFileList.push({url: fileUrl[indexThree], name: itemThree, type: Config.fileType.imgType.includes(itemThree.split('.')[itemThree.split('.').length - 1]) ? 'imgText' : 'file'})
                    })
                    item.value.fileList = itemFileList
                  }
                })
              }
            }
            
          } else {
            item.value = []
          }
        })
      }
      this.followRecordList = arrGroup(resData)
      setTimeout(() => {
        this.testFunc()
      }, 100);
    },
    testFunc() {
      let lianjieHtml = document.getElementsByClassName("lianjie")
      if (lianjieHtml && lianjieHtml.length) {
        let textHtml
        let reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-|#)+)/g;
        for(let i = 0; i < lianjieHtml.length; i++){
          textHtml = lianjieHtml[i].innerText
          textHtml = textHtml.replace(reg, "<a style='color:#1890ff;' target='_blank' href='$1$2'>$1$2</a>");
          lianjieHtml[i].innerHTML = textHtml
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.detailTitle {
  font-size: 18px;
  color: #101010;
  padding: 40px 0 14px;
  border-top: 1px solid #eaeaeb;
}
.aboutInfo {
  border: 1px solid #eaeaeb;
  border-radius: 4px;
  padding: 12px 24px;
  div {
    width: 32%;
    display: inline-flex;
    line-height: 40px;
    font-size: 14px;
    color: #999;
    margin-right: 1%;
    span {
      color: #101010;
      flex: 1;
    }
  }
}
.common-multiple-container {
    width: 100%;
    height: auto;
    overflow: auto;
    margin: 0 0 16px 0;
    
    .label {
        width: 88px;
        padding: 0;
        text-align: center;
        display: flex;
        align-items: center;
    }
    .content {
        flex: 1;
        overflow-x: auto;
        overflow-y: auto;
        flex-wrap: wrap;
        padding-bottom: 8px;
        max-height: 108px;
        .multiple-item {
            margin-top: 8px;
        }
    }
    .multiple-btn {
        width: 98px;
        text-align: center;
        padding: 0;
    }
}
/deep/.el-tabs__content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
/deep/.el-tab-pane {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.taskDetail-drawer {
  text-align: left;
  color: #101010;
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .person-container {
      border-top: 1px solid #eaeaeb;
      border-bottom: 1px solid #eaeaeb;
      margin: 28px 0;
      padding: 16px 0;
      div {
        display: flex;
        align-items: center;
        height: 44px;
        line-height: 44px;
        color: #999;
        span {
          color: #333;
        }
      }
    }
    .upload-container {
        margin: 40px 0;
        .fileList-container {
          border: 1px solid #EBEEF5;
          margin-top: 12px;
          border-radius: 4px;
          .fileItem {
            display: flex;
            justify-content: space-between;
            padding: 0 24px;
            border-bottom: 1px solid #EBEEF5;
            height: 74px;
            line-height: 74px;
            align-items: center;
            .fileItem-btn-Container {
              display: flex;
              .fileItemBtn {
                margin-right: 16px;
                color: #1890ff;
                cursor: pointer;
              }
            }
          }
        }
      }
    .drawer-left {
      padding: 0 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
      .handle-btn {
        width: 120px;
        height: 40px;
        line-height: 40px;
        border-radius: 4px;
        color: #fff;
        background: #1890FF;
        text-align: center;
        line-height: 40px;
        font-size: 14px;
        cursor: pointer;
        margin-top: 24px;
      } 
      .grey-btn {
        width: 120px;
        height: 40px;
        line-height: 41px;
        border-radius: 4px;
        color: #1890ff;
        background: #fff;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        margin-top: 24px;
        border: 1px solid #1890ff;
      }
      .detail-title {
        line-height: 78px;
        .tit-info {
            display: flex;
            align-items: center;
            .orderName {
                color: #101010;
                font-size: 18px;
                margin-right: 16px;
            }
        }
        span:nth-of-type(1) {
          font-size: 18px;
          margin-right: 16px;
        }
      }
      .detail-content {
        width: 900px;
        .DisableEdit {
            display: inline-block;
            width: 323px;
            height: 21px;
            padding: 6px 12px;
            font-size: 12px;
            color: #999;
            margin-right: 16px;
            border-radius: 4px;
        }
      }
      .detail-item {
        width: 32%;
        display: inline-block;
        margin-bottom: 16px;
        color: #999;
        span {
          color: #666;
        }
      }
      .detail-remark {
        display: flex;
        width: 80%;
        margin-bottom: 16px;
        span {
          display: inline-block;
          &:nth-of-type(1) {
            color: #999;
            white-space: nowrap;
          }
          &:nth-of-type(2) {
            color: #666;
          }
        }
      }
      .leftTabs {
        margin-top: 36px;
      }
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      .appeal-commit {
          margin-top: 16px;
        text-align: right;
        .addFileBtn {
          height: 20px;
          color: #1890ff;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
          .addFileInput {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 20px;
            opacity: 0;
            cursor: pointer;
            font-size: 0;
          }
        }
      }
      
    }
  }
  /deep/ .el-drawer__open .el-drawer.rtl {
      width: 1656px !important;
  }
  /deep/ .el-drawer__body {
      padding: 0;
  }
  /deep/ .el-tabs__content {
      padding: 0 8px 0 16px;
  }
}
</style>
